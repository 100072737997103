import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import {
  Section,
  SectionHeading,
  SectionLedeDiv,
} from "../../styledComponents/section"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)



const Thankyou = ({ data, location }) => {
  
  const setFbPixelTracker = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const event = urlParams.get('event') 
    const title = 'FREE:' + event
    const status = true
  }

  useEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      setFbPixelTracker()
    }
  }, [])


  return (
    <>
    <Layout displayNavBorder location={location}>
      <SEO title={data.wpPage.title} />
      <Section>
        <Heading level={1} align="left">
          {data.wpPage.title}
        </Heading>
        <SectionLedeDiv>
        <p>Thank you for your interest in attending the live talk.</p>
        <p>If you have any questions please can you <Link to="/contact">get in contact</Link> and send an email ahead of the talk.</p>
        <p>I look forward to seeing you on the day.</p>
        {/* <p>It will be a wonderful way to come together and offer each other a group healing.</p>

        <h2>Important information:</h2>
        <p>This is a group healing session for people that have previously attended a live or online Quantum-Touch Level 1 workshop.</p>
        <p>AND: bring one friend who wishes to sit with us and enjoy the group healing energy.</p> 
        <p>Please spend 5 - 10 minutes giving yourself Quantum-Touch before you sign in to the call. If we all do this then we will start the healing from a higher frequency.</p>
        <p>The group healing session is a live online event and is not being recorded so please sign-in on time.</p> */}
        
        </SectionLedeDiv>       
      </Section>
    </Layout>
    </>
  )
}

export default Thankyou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "thank-you" }) {
      title
      content
    }
  }
`
